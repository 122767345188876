<template>
  <main>
    <div class="banner">
      <img v-lazy="require('./jtzn_img/banner.webp')" alt="" class="bannerr" />
      <span class="jtzn">交通指南</span>
      <span class="js"
        >提供景区公交、地铁、大巴、 自驾等交通方式，为游客保驾护航</span
      >
    </div>
    <div class="bg1">
      <span class="ys">交通指南的优势</span>
      <span class="fh1"
        >给游客提供更便捷的出行服务，全旅程的精准定位服务，让游客快速找到目标路线。
      </span>
      <div class="img11">
        <img v-lazy="require('./jtzn_img/bg1.webp')" class="image1" />
      </div>
    </div>
    <div class="bg2">
      <span class="jqw">景区外的交通指南</span>
      <span class="fh2"
        >为游客详细的规划了到达景区的详细路线，给游客指引方向，让游客有规划的到达景区
      </span>
      <div class="box1">
        <img src="./jtzn_img/icon1.png" alt="" class="icon1" />
        <span class="fj1">飞机</span>
        <span class="sfh1">从机场到达景区的路线规划</span>
      </div>
      <div class="box2">
        <img src="./jtzn_img/icon2.png" alt="" class="icon2" />
        <span class="fj2">高铁</span>
        <span class="sfh2">从高铁站到景区的路线规划</span>
      </div>
      <div class="box3">
        <img src="./jtzn_img/icon3.png" alt="" class="icon3" />
        <span class="fj3">火车</span>
        <span class="sfh3">从火车站到景区路线的规划</span>
      </div>
      <div class="box4">
        <img src="./jtzn_img/icon4.png" alt="" class="icon4" />
        <span class="fj4">自驾</span>
        <span class="sfh4">提供景区周边的交通示意图</span>
      </div>
      <div class="box5">
        <img src="./jtzn_img/icon5.png" alt="" class="icon5" />
        <span class="fj5">免费公交</span>
        <span class="sfh5">提供到达景区的免费公交的站点和发车时间</span>
      </div>
    </div>
    <Header></Header>
    <Footer></Footer>
  </main>
</template>

<script>
//引入头部组件
import Header from "@/components/Header.vue";
// 引入底部组件
import Footer from "@/components/Footer.vue";
export default {
  components: {
    Header,
    Footer,
  },
};
</script>

<style lang="scss" scoped>
* {
  padding: 0;
  margin: 0;
}
.banner {
  height: 593px;
  // background-image: url(./jtzn_img/banner.png);
  background-size: 100%;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  img[lazy="loading"] {
    width: 200px;
    height: 200px;
  }
  // 实际图片大小
  img[lazy="loaded"] {
    height: 600px;
    width: 100%;
  }
  .banner {
    height: 593px;
  }
  .jtzn {
    min-width: 206px;
    height: 67px;
    font-size: 48px;
    color: #ffffff;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
    position: absolute;
    top: 230px;
    left: 361px;
    text-align: left;
  }
  .js {
    min-width: 600px;
    height: 66px;
    font-size: 24px;
    color: #ffffff;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
    position: absolute;
    top: 321px;
    left: 361px;
    text-align: left;
  }
}
.bg1 {
  height: 785px;
  position: relative;
  .ys {
    min-width: 289px;
    height: 56px;
    font-size: 40px;
    color: #333333;
    position: absolute;
    top: 80px;
    left: 815px;
  }
  .fh1 {
    min-width: 1115px;
    height: 40px;
    font-size: 20px;
    color: #565656;
    position: absolute;
    top: 160px;
    left: 402px;
    text-align: center;
  }
  .img11 {
    width: 1200px;
    height: 420px;
    position: absolute;
    top: 260px;
    left: 361px;
    display: flex;
    align-items: center;
    justify-content: center;
    img[lazy="loading"] {
      width: 200px;
      height: 200px;
    }
    // 实际图片大小
    img[lazy="loaded"] {
      height: 420px;
      width: 100%;
    }
    .image1 {
      width: 1200px;
    }
  }
}
.bg2 {
  //   min-width: 1920px;
  height: 591px;
  background: #f6f8fb;
  position: relative;
  .jqw {
    position: absolute;
    min-width: 330px;
    height: 56px;
    font-size: 40px;
    color: #333333;
    top: 75px;
    left: 794px;
  }
  .fh2 {
    min-width: 1115px;
    height: 40px;
    font-size: 20px;
    color: #565656;
    position: absolute;
    top: 155px;
    left: 402px;
    text-align: center;
  }
  .box1 {
    min-width: 216px;
    height: 236px;
    background: #ffffff;
    border-radius: 6px;
    position: absolute;
    top: 255px;
    left: 361px;
    .icon1 {
      height: 64px;
      min-width: 64px;
      position: absolute;
      top: 37px;
      left: 76px;
    }
    .fj1 {
      min-width: 42px;
      height: 28px;
      font-size: 20px;
      color: #333333;
      position: absolute;
      top: 120px;
      left: 88px;
    }
    .sfh1 {
      width: 148px;
      height: 40px;
      font-size: 14px;
      color: #666666;
      position: absolute;
      top: 164px;
      left: 34px;
      text-align: center;
    }
  }
  .box2 {
    min-width: 216px;
    height: 236px;
    background: #ffffff;
    border-radius: 6px;
    position: absolute;
    top: 255px;
    left: 607px;
    .icon2 {
      height: 64px;
      min-width: 64px;
      position: absolute;
      top: 37px;
      left: 76px;
    }
    .fj2 {
      min-width: 42px;
      height: 28px;
      font-size: 20px;
      color: #333333;
      position: absolute;
      top: 120px;
      left: 88px;
    }
    .sfh2 {
      width: 148px;
      height: 40px;
      font-size: 14px;
      color: #666666;
      position: absolute;
      top: 164px;
      left: 34px;
      text-align: center;
    }
  }
  .box3 {
    min-width: 216px;
    height: 236px;
    background: #ffffff;
    border-radius: 6px;
    position: absolute;
    top: 255px;
    left: 853px;
    .icon3 {
      height: 64px;
      min-width: 64px;
      position: absolute;
      top: 37px;
      left: 76px;
    }
    .fj3 {
      min-width: 42px;
      height: 28px;
      font-size: 20px;
      color: #333333;
      position: absolute;
      top: 120px;
      left: 88px;
    }
    .sfh3 {
      width: 148px;
      height: 40px;
      font-size: 14px;
      color: #666666;
      position: absolute;
      top: 164px;
      left: 34px;
      text-align: center;
    }
  }
  .box4 {
    min-width: 216px;
    height: 236px;
    background: #ffffff;
    border-radius: 6px;
    position: absolute;
    top: 255px;
    left: 1099px;
    .icon4 {
      height: 64px;
      min-width: 64px;
      position: absolute;
      top: 37px;
      left: 76px;
    }
    .fj4 {
      min-width: 42px;
      height: 28px;
      font-size: 20px;
      color: #333333;
      position: absolute;
      top: 120px;
      left: 88px;
    }
    .sfh4 {
      width: 148px;
      height: 40px;
      font-size: 14px;
      color: #666666;
      position: absolute;
      top: 164px;
      left: 34px;
      text-align: center;
    }
  }
  .box5 {
    min-width: 216px;
    height: 236px;
    background: #ffffff;
    border-radius: 6px;
    position: absolute;
    top: 255px;
    left: 1345px;
    .icon5 {
      height: 64px;
      min-width: 64px;
      position: absolute;
      top: 37px;
      left: 76px;
    }
    .fj5 {
      min-width: 84px;
      height: 28px;
      font-size: 20px;
      color: #333333;
      position: absolute;
      top: 120px;
      left: 67px;
    }
    .sfh5 {
      width: 148px;
      height: 40px;
      font-size: 14px;
      color: #666666;
      position: absolute;
      top: 164px;
      left: 34px;
      text-align: center;
    }
  }
}
.bg3 {
  height: 243px;
  position: relative;
  .image2 {
    min-width: 359px;
    height: 47px;
    position: absolute;
    top: 100px;
    left: 711px;
  }
  .lhcp {
    min-width: 132px;
    height: 45px;
    font-size: 32px;
    color: #333333;
    position: absolute;
    top: 100px;
    left: 1081px;
  }
}
</style>
